@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconf-"], [class*=" iconf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconf-arrow-down {
  &:before {
    content: $iconf-arrow-down; 
  }
}
.iconf-arrow-left {
  &:before {
    content: $iconf-arrow-left; 
  }
}
.iconf-arrow-right {
  &:before {
    content: $iconf-arrow-right; 
  }
}
.iconf-arrow-up {
  &:before {
    content: $iconf-arrow-up; 
  }
}
.iconf-plus {
  &:before {
    content: $iconf-plus; 
  }
}
.iconf-minus {
  &:before {
    content: $iconf-minus; 
  }
}
.iconf-close {
  &:before {
    content: $iconf-close; 
  }
}
.iconf-tick {
  &:before {
    content: $iconf-tick; 
  }
}
.iconf-link {
  &:before {
    content: $iconf-link; 
  }
}
.iconf-internet {
  &:before {
    content: $iconf-internet; 
  }
}
.iconf-calendar {
  &:before {
    content: $iconf-calendar; 
  }
}
.iconf-external {
  &:before {
    content: $iconf-external; 
  }
}
.iconf-refresh {
  &:before {
    content: $iconf-refresh; 
  }
}
.iconf-download {
  &:before {
    content: $iconf-download; 
  }
}
.iconf-telephone {
  &:before {
    content: $iconf-telephone; 
  }
}
.iconf-email {
  &:before {
    content: $iconf-email; 
  }
}
.iconf-print {
  &:before {
    content: $iconf-print; 
  }
}
.iconf-info {
  &:before {
    content: $iconf-info; 
  }
}
.iconf-faq {
  &:before {
    content: $iconf-faq; 
  }
}
.iconf-maps-marker {
  &:before {
    content: $iconf-maps-marker; 
  }
}
.iconf-photo {
  &:before {
    content: $iconf-photo; 
  }
}
.iconf-facebook {
  &:before {
    content: $iconf-facebook; 
  }
}
.iconf-instagram {
  &:before {
    content: $iconf-instagram; 
  }
}
.iconf-linkedin {
  &:before {
    content: $iconf-linkedin; 
  }
}
.iconf-pinterest {
  &:before {
    content: $iconf-pinterest; 
  }
}
.iconf-play {
  &:before {
    content: $iconf-play; 
  }
}
.iconf-twitter {
  &:before {
    content: $iconf-twitter; 
  }
}
.iconf-weibo {
  &:before {
    content: $iconf-weibo; 
  }
}
.iconf-youtube {
  &:before {
    content: $iconf-youtube; 
  }
}
.iconf-search {
  &:before {
    content: $iconf-search; 
  }
}


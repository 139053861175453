﻿// THEME

$white: #fff;
$black: #000;

$grey--lightest: #f2f2f2;
$grey--light: #ececec;
$grey--pale: #efefef;
$grey: #cbcbcb;
$grey--dark: #494c4d;
$grey--darkest: #221E1F;

$blue: #144866;
$blue--dark: #06334C;

$skyblue--lightest: #EAF4F7;
$skyblue--light: #8AD4E3;
$skyblue: #44ADB3;

$red: #C32032;
$red--dark: #780c15;

$orange: #f28907;


$brand: $blue;
$brand--alt: $skyblue;
$brand--alt-light: $skyblue--light;

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: lighten($error, 40);

// Assign colours to common variables
$body-color: $black;
$body-grey-color: #333;
$base-ui-color: $grey--light;

// Main Colours
$primary: $brand;
$primary-text: $white;
$secondary: darken($brand, 10);
$secondary-text: $white;


// Headings
$heading: $brand;

// Buttons
// Variants are: Primary - Light - Outline
$btn-bg: $brand;
$btn-text: $white;
$btn-bg-hover: darken($brand, 10);
$btn-text-hover: $white;
$btn-light-bg: $white;
$btn-light-text: $brand;
$btn-light-bg-hover: darken($brand, 10);
$btn-light-text-hover: $white;
$btn-outline-bg: $white;
$btn-outline-text: $brand;
$btn-outline-border: $brand;
$btn-outline-bg-hover: darken($brand, 10);
$btn-outline-text-hover: $white;
$btn-outline-border-hover: darken($brand, 10);

// Links
$link-text: $brand;
$link-text-hover: darken($brand, 10);
$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-arrow-down: "\e900";
$iconf-arrow-left: "\e901";
$iconf-arrow-right: "\e902";
$iconf-arrow-up: "\e903";
$iconf-plus: "\e904";
$iconf-minus: "\e905";
$iconf-close: "\e906";
$iconf-tick: "\e907";
$iconf-link: "\e908";
$iconf-internet: "\e909";
$iconf-calendar: "\e90a";
$iconf-external: "\e90b";
$iconf-refresh: "\e90c";
$iconf-download: "\e90d";
$iconf-telephone: "\e90e";
$iconf-email: "\e90f";
$iconf-print: "\e910";
$iconf-info: "\e911";
$iconf-faq: "\e912";
$iconf-maps-marker: "\e913";
$iconf-photo: "\e914";
$iconf-facebook: "\e915";
$iconf-instagram: "\e916";
$iconf-linkedin: "\e917";
$iconf-pinterest: "\e918";
$iconf-play: "\e919";
$iconf-twitter: "\e91a";
$iconf-weibo: "\e91b";
$iconf-youtube: "\e91c";
$iconf-search: "\e91d";


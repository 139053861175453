$base-font-family: 'Roboto', sans-serif;
$heading-font-family: 'Titillium Web', sans-serif;

// Icons
$icomoon-font-path: '/Content/fonts/font-icons/fonts';

@import "../fonts/font-icons/variables";
@import "theme";
@import "main";

// Jermini specific targeted overrides
.o-layout-page {
	position: relative;
	padding-top: 100px;

	@include bp("large") {
		padding-top: 160px;
	}

	&.hasHero {
		padding-top: $bsu;
	}

	.c-hubhero__wrapper ~ &,
	.c-hero__wrapper ~ & {
		padding-top: 60px;
	}

	&.o-layout-search-page,
	&.o-layout-404-page,
	&.o-layout-page--no-hero {
		padding-top: 100px;

		@include bp("large") {
			padding-top: 160px;
		}
	}
}

.c-hero__primary {
	padding-bottom: 0;
}

@import "../fonts/font-icons/style.scss";